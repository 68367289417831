html {
  --background: #3b4252;
  --block-background: #4d53627f;
  --text-default: #fff;
  --text-header: #3bbde3;
  --text-body: #00b7eb;
  --text-link: #3391ff;
  --list-bullet: #fa8072;
}

body {
  background-color: var(--background);

}

h1, h2, h3, p, li {
  color: var(--text-default);
}

li::marker {
  color: var(--list-bullet);
}

a {
  color: var(--text-link);
  text-decoration: none;
}

.root {
  width: 100%;
}

.App {
  display: flex;
  padding: auto;
  padding-top: 1%;
  margin: 25%;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

.App .row {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.App .row {
  margin: auto;
  display: block;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
  padding: 0;
  margin: 0 5%;
}

.App-header h1 {
  margin: 1px;
}

.App-link {
  color: #61dafb;
}

.container {
  margin: auto;
}

.list-card {
  text-align: left;
  max-width: inherit;
}

.list-card h2, .list-card h3 {
  color: var(--text-header);
}

.list-card h3 {
  margin: 30px auto 1px auto;
}

.list-card li, .list-card p {
  color: var(--text-body);
}

.list-card li {
  list-style-type: square;
}

.list-card > ul {
  margin: 1em;
}


.mainPanel {
  float: left;
  max-width: 700px;
  min-width: 500px;
  padding: 4px;
  padding-right: 30px;
}

.sidepanel {
  height: 100%;
  float: left;
  transform: translateY(15%);
}

.links-list {
  background-color: var(--block-background);
  padding: 5px;
  border-radius: 2.5em;
}

.links-list h2 {
  text-align: center;
}

footer {
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
}

footer a {
  margin: 0 5px;
}